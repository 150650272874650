import React, { useEffect } from 'react';

const Partners = () => {
  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    location.href = 'https://partners.medistaff.co.kr';
  }, []);
  return null;
};

export default Partners;
